import ApiService from "./api.service";
import moment from "moment";
import JwtService from "./jwt.service";

//actions
export const GET_PAYMENTS = "getPayments";
export const GET_PAYMENT = "getPayment";
export const RESEND_RECEIPT = "resendReceipt";
export const GENERATE_PAYLINK = "generatePaylink";
export const GET_PAYMENT_METHOD = "getPaymentMethod";
export const CREATE_MANUAL_PAYMENT = "createManualPayment";

//mutations 
export const SET_PAYMENTS = "setPayments";
export const SET_PAYMENT = "setPayment";
export const SET_CREATE_INVOICE = "setCreateInvoice";
export const SET_TEMPLATES = "setTemplates";
export const SET_PAYLINK = "setPaylink"
export const SET_PAYMENT_METHOD = "setPaymentMethod";

const convertStatus = {
  init: {
    status: "Waiting Payment",
    color: "pending"
  },
  processed: {
    status: "Processed",
    color: "paid"
  },
  created: {
    status: "Waiting Payment",
    color: "pending"
  },
  canceled: {
    status: "Canceled",
    color: "canceled"
  },
  expired: {
    status: "Expired",
    color: "canceled"
  },
}

const convertSettlementStatus = {
  settlement: {
    status: "Settlement",
    color: "paid"
  },
  waiting: {
    status: "Waiting",
    color: "pending"
  }
}

const convertDeliveryStatus = {
  pending: "Pending",
  sent: "Sent",
  draft: "Draft",
}

const state = {
  payments: [],
  payment: {},
  createInvoice: JwtService.getCreateInvoice(),
  templates: [],
  payment_methods: [],
  paylink: ""
}

const getters = {
  currentPayments(state) {
    return state.payments;
  },
  paymentLink(state) {
    return state.paylink
  },
  currentPayment(state) {
    return state.payment;
  },
  currentPaymentMethods(state) {
    return state.payment_methods;
  },
}

const actions = {
  [GET_PAYMENTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/payments", params)
        .then(async ({ data }) => {
          Object.assign(data.data, { page: params.page })
          await context.commit(SET_PAYMENTS, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_PAYMENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/payments/" + params)
        .then(async ({ data }) => {
          await context.commit(SET_PAYMENT, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [RESEND_RECEIPT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/payments/" + params + "/resend")
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  [GENERATE_PAYLINK](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/paylink", params)
        .then(async ({ data }) => {
          await context.commit(SET_PAYLINK, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  [GET_PAYMENT_METHOD](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/payment-method", params)
        .then(async ({ data }) => {
          await context.commit(SET_PAYMENT_METHOD, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  [CREATE_MANUAL_PAYMENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/payments", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  }
}

const mutations={
  [SET_PAYMENTS](state, data) {

    // handle convert data
    data.rows = data.rows.map(d => {
      d.status_str = d.status ? convertStatus[d.status].status : "";
      d.status_color = d.status ? convertStatus[d.status].color : "";
      d.delivery_status_str = d.delivery_status ? convertDeliveryStatus[d.delivery_status] : "";
      d.settlement_status_str = d.settlement_status ? convertSettlementStatus[d.settlement_status].status : ""
      d.settlement_status_color = d.settlement_status ? convertSettlementStatus[d.settlement_status].color : ""
      d.created_at_str = moment(d.payment_date).format("DD/MM/YYYY")
      d.amount_str = `Rp. ${d.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      d.selected = false
      return d
    })

    // handle total page, and etc
    let limit = 10;
    const start = 1 + ((data.page - 1) * limit)
    const maxData = data.page * limit;
    let last_row = 0;
    if (data.count > maxData) {
      last_row = maxData;
    } else {
      last_row = (data.count % limit) + ((data.page - 1) * limit);
    }

    // handle pagination
    const total_page = Math.ceil(data.count / limit);
    let page_list = [];
    const diff = total_page - data.page;
    if (diff >= 5) {
      page_list = [data.page, data.page+1, "...", total_page-1, total_page];
    } else {
      if (total_page > 5) {
        for (let i=total_page-4; i<=total_page; i++) {
          page_list.push(i);
        }
      } else {
        for (let i=1; i<=total_page; i++) {
          page_list.push(i);
        }
      }
    }
    
    Object.assign(data, { start, last_row, page_list, total_page })
    state.payments = data;
    return;
  },
  [SET_PAYMENT](state, data) {
    if (!state.payment[data.payment.id]) {
      const getData = state.payment;
      data.detail = data.detail.map(d => {
        let price = d.grand_total
        if (d.grand_total && d.grand_total > 0) price = Number(d.grand_total).toLocaleString("id-ID");
        d.invoice_date_str = moment(d.invoice_date).format("DD/MM/YYYY")
        d.invoice_due_date_str = moment(d.invoice_due_date).format("DD/MM/YYYY")
        d.final_amount_str = `Rp. ${price}`;
        return d
      })
      Object.assign(getData, { [data.payment.id]: data })
      state.payment = getData;
    }
    return;
  },
  [SET_PAYLINK](state, data) {
    state.paylink = data.url;
    return;
  },
  [SET_PAYMENT_METHOD](state, data) {
    state.payment_methods = data.rows;
    return;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
