<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative w-100 md:w-64 z-10 py-6">
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
      <modal :title="mconfig.title" :description="mconfig.text" :isVisibility="mconfig.show" :type="mconfig.type"
        @closeModal="mconfig.show = false" />
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button" v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <div class="py-4 px-3 flex justify-center">
        <img :src="ventenyLogo" />
      </div>
      <!-- User -->
      <!-- <span class="mb-0 mt-4 text-md text-black-900 px-8 block font-semibold">Menu</span> -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown-component></notification-dropdown-component>
        </li>
        <li class="inline-block relative">
          <user-dropdown-component></user-dropdown-component>
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1"
        v-bind:class="collapseShow">
        <!-- Collapse header -->
        <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
          <div class="flex flex-wrap">
            <div class="w-6/12 flex justify-end">
              <button type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input type="text" placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal" />
          </div>
        </form>
        <!-- Navigation -->
        <ul class="side-menu md:flex-col md:min-w-full flex flex-col list-none -px-5">
          <li class="items-center" :class="{ 'active': active == 'dashboard' }">
            <a class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="$router.push({ name: 'Dashboard' })">
              <img :src="active == 'dashboard' ? HomeIconActive : HomeIcon" alt="icon" class="inline-block" />
              Dashboard</a>
          </li>
          <li class="items-center" :class="{ 'active': active == 'invoice' }">
            <a class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="$router.push({ name: 'Invoice' })">
              <img :src="active == 'invoice' ? InvoiceIconActive : InvoiceIcon" alt="icon" class="inline-block" />
              Invoice</a>
          </li>
          <li class="items-center" :class="{ 'active': active == 'payments' }">
            <a class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="$router.push({ name: 'Payments' })">
              <img :src="active != 'payments' ? PaymentsIcon : PaymentsIconActive" alt="icon" class="inline-block" />
              Payments</a>
          </li>
          <li class="items-center" :class="{ 'active': active == 'loan' }">
            <a class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="comingSoon();">
              <img :src="LoanIcon" alt="icon" class="inline-block" />
              Loan</a>
          </li>
          <li class="items-center" :class="{ 'active': active == 'mitra' }">
            <a class="text-blueGray-700 hover:text-blueGray-500  text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="$router.push({ name: 'Mitra' })">
              <img :src="active == 'mitra' ? MitraIconActive : MitraIcon" alt="icon" class="inline-block" />
              Mitra</a>
          </li>
          <!-- <li class="items-center" :class="{ 'active': active == 'salesperson' }">
            <a
              class="ttext-blueGray-700 hover:text-blueGray-500  text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="$router.push({ name: 'Sales Person' })"
              >
              <img :src="SalesPersonIcon" alt="icon" class="inline-block" />
              Sales Person</a
            >
          </li> -->
          <li class="items-center">
            <a class="ttext-blueGray-700 hover:text-blueGray-500  text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="comingSoon();">
              <img :src="ReportIcon" alt="icon" class="inline-block" />
              Laporan</a>
          </li>
          <li class="items-center">
            <a class="text-blueGray-700 hover:text-blueGray-500  text-xs uppercase px-5 py-1 font-bold block pointer"
              @click="comingSoon();">
              <img :src="SummaryIcon" alt="icon" class="inline-block" />
              Summary</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NotificationDropdownComponent from "./NotificationDropdown.vue";
import UserDropdownComponent from "./UserDropdown.vue";
// import InlineSvg from "vue-inline-svg";
import VentenyLogo from "../assets/img/venteny-logo.svg";
import HomeIcon from "../assets/img/ic-dashboard.svg";
import InvoiceIcon from "../assets/img/ic-invoice.svg";
import PaymentsIcon from "../assets/img/ic-payments.svg";
import LoanIcon from "../assets/img/ic-loan.svg";
import MitraIcon from "../assets/img/ic-mitra.svg";
import SummaryIcon from "../assets/img/ic-summary.svg";
import SalesPersonIcon from "../assets/img/ic-salesperson.svg";
import ReportIcon from "../assets/img/ic-report.svg";
import Modal from './modal/Modal.vue';
import PaymentsIconActive from '../assets/img/payment-icon-active.svg';
import InvoiceIconActive from '../assets/img/invoice-icon-active.svg';
import HomeIconActive from '../assets/img/home-icon-active.svg';
import MitraIconActive from '../assets/img/mitra-icon-active.svg';

// Your component

export default {
  props: {
    active: {
      type: String,
      default: "dashboard"
    }
  },
  data() {
    return {
      collapseShow: "hidden",
      ventenyLogo: VentenyLogo,
      HomeIcon: HomeIcon,
      LoanIcon: LoanIcon,
      InvoiceIcon: InvoiceIcon,
      MitraIcon: MitraIcon,
      PaymentsIcon: PaymentsIcon,
      SummaryIcon: SummaryIcon,
      SalesPersonIcon: SalesPersonIcon,
      ReportIcon: ReportIcon,
      PaymentsIconActive,
      InvoiceIconActive,
      HomeIconActive,
      MitraIconActive,
      mconfig: {
        show: false,
        title: "",
        text: "",
        type: "success"
      }
    };
  },
  methods: {
    toggleCollapseShow: function(classes) {
      this.collapseShow = classes;
    },
    comingSoon() {
      this.$parent.$data.mconfig = {
        show: true,
        title: "Segera hadir!",
        text: "Wah, mohon maaf fitur ini belum tersedia untuk saat ini. Namun, fitur ini akan segera hadir!",
        type: "info"
      }
    }
  },
  components: {
    NotificationDropdownComponent,
    UserDropdownComponent,
    Modal,
    // InlineSvg,
  },
};
</script>
