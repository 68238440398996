<template>
  <div>
    <div v-if="isVisibility" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="h-full flex items-center justify-center min-h-screen pt-0 px-0 pb-0 text-center sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 overlay transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. --> 
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="modal-md inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-5 py-5">
            <div class="block modal-body">
              <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-bold text-gray-900 mb-2" id="modal-title">
                  Kalkulator Pinjaman
                </h3>
                
                <div class="mt-2 w-full modal-content">
                  <Tabs>
                  <Tab title="Jumlah Pinjaman">
                  </Tab>
                  <Tab title="Pembayaran per periode">Hello From Tab 2</Tab>
                </Tabs>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Jumlah Pinjaman
                    </label>
                    <div class="w-">
                      <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Nomor Mitra" />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white shadow-top px-4 py-3 px-6 flex flex-row-reverse">
            
            <button type="button" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 bg-orange-500 text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ml-3 w-auto text-xs">
              Buat Invoice
            </button>
            <button @click.prevent="toggleModal" type="button" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 bg-gray text-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-3 w-auto text-xs">
              Batalkan
            </button>
          </div>
        </div>
      </div>
    </div>  
    <button
      type="button"
      class="text-sm bg-gray-300 text-black px-8 py-3 rounded-lg outline-none focus:outline-none mr-2 mb-1"
      style="transition: all 0.15s ease 0s;"
      @click.prevent="toggleModal"
    >
      Loan Calculator
    </button>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import ClinetSpupplier from "../assets/img/client-supplier.svg";
import Supplier from "../assets/img/supplier.svg";
import Client from "../assets/img/client.svg";
import Tab from './tabs/Tab.vue';
import Tabs from './tabs/Tabs.vue';
export default {
  name: "loan-calculator-modal",
  components: {
    Tab,
    Tabs
  },
  data() {
    return {
      ClinetSpupplier: ClinetSpupplier,
      Supplier: Supplier,
      Client: Client,
      isVisibility: false
    }
  },
  methods: {
    toggleModal() {
      this.isVisibility =  !this.isVisibility
    }
  }
};
</script>
