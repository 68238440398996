<template>
  <div class="w-full h-1/2 mb-12 xl:mb-0 px-4 ">
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 opacity-100 rounded bg-white"
    >
      <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h6 class="text-black-100 mb-1 text-xs font-semibold">
              Total Revenue
            </h6>
            <h2 class="text-lack text-xs ">
              Available Balance
            </h2>
          </div>
        </div>
      </div>
      <div class="p-4 flex-auto">
        <!-- Chart -->
        <div class="relative">
          <canvas id="pie-chart"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";

export default {
  mounted: function() {
    this.$nextTick(function() {
      var config = {
        type: "pie",
        data: {
          labels: [
            'Ourstanding Ammount',
            ' ',
            'Ammount Received'
          ],
          datasets: [{
            label: 'My First Dataset',
            data: [300, 50, 100],
            backgroundColor: [
              'rgba(255, 143, 21, 1)',
              'rgb(246, 243, 235)',
              'rgba(29, 35, 50, 1)'
            ],
            hoverOffset: 4
          }]
        },
      };
      var ctx = document.getElementById("pie-chart").getContext("2d");
      window.myLine = new Chart(ctx, config);
    });
  },
};
</script>
