<template>
  <div>
    <sidebar-component active="summary"></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100 h-screen pb-wrap">
      <!-- Header -->
      <Header page="General" />
      <div class="px-4 md:px-10 mx-auto w-full">
        <Tabs>
          <Tab title="General Summary">
            <div class="-mx-4 flex flex-wrap py-10">
              <div class="px-4 lg:w-8/12">
                <div class="chart">
                  <line-chart-component></line-chart-component>
                  <!-- <bar-chart-component></bar-chart-component> -->
                </div>
              </div>
              <div class="px-4 lg:w-4/12">
                <div class="chart">
                  <PieChart />
                </div>
              </div>
              <div class="px-4 mt-10 w-full">
                <div class=" w-full">
                  <!-- Projects table -->
                  <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th style="width: 20px;"
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          <input type="checkbox"
                            class="appearance-none border border-blueGray-200 rounded checked:bg-orange-600 checked:border-transparent ..." />
                        </th>
                        <th
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Invoice No
                          <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                            <ChevronUpIcon class="text-black-400 text-xs -mb-half" />
                            <ChevronDownIcon class="text-black-400 text-xs" />
                          </span>
                        </th>
                        <th
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Client Name
                          <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                            <ChevronUpIcon class="text-black-400 text-xs -mb-half" />
                            <ChevronDownIcon class="text-black-400 text-xs" />
                          </span>
                        </th>
                        <th
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Project Name
                          <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                            <ChevronUpIcon class="text-black-400 text-xs -mb-half" />
                            <ChevronDownIcon class="text-black-400 text-xs" />
                          </span>
                        </th>
                        <th
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Issued
                          <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                            <ChevronUpIcon class="text-black-400 text-xs -mb-half" />
                            <ChevronDownIcon class="text-black-400 text-xs" />
                          </span>
                        </th>
                        <th
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Payment Status
                          <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                            <ChevronUpIcon class="text-black-400 text-xs -mb-half" />
                            <ChevronDownIcon class="text-black-400 text-xs" />
                          </span>
                        </th>
                        <th
                          class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Amount
                          <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                            <ChevronUpIcon class="text-black-400 text-xs -mb-half" />
                            <ChevronDownIcon class="text-black-400 text-xs" />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white border border-solid border-blueGray-100 border-l-0 border-r-0 pointer">
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <input type="checkbox"
                            class="appearance-none border border-blueGray-200 rounded checked:bg-orange-600 checked:border-transparent ..." />
                        </td>
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <span class="rounded-full bg-gray-100 px-2 py-2">
                            <!-- {{ item.invoice_no }} -->
                          </span>
                        </td>
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <!-- {{ item.partner.name }} -->
                        </td>
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <!-- <span class="status" :class="item.status_color">{{
                            item.status_str
                          }}</span> -->
                        </td>
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <span class="status delivery">
                            <!-- {{ item.delivery_status_str }} -->
                          </span>
                        </td>
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <!-- {{ item.grand_total_str }} -->
                        </td>
                        <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                          <!-- {{ item.invoice_due_date_str }} -->
                        </td>
                      </tr>
                      <!-- <Loading color='#FF8F15' v-model:active="isLoading" :is-full-page="false" />
                      <template v-for="(item, index) in invoices.rows" :key="index">
                        <tr class="bg-white border border-solid border-blueGray-100 border-l-0 border-r-0 pointer"
                          @click="handleSelected(index)">
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            <input type="checkbox"
                              class="appearance-none border border-blueGray-200 rounded checked:bg-orange-600 checked:border-transparent ..."
                              v-model="item.selected" />
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            <span class="rounded-full bg-gray-100 px-2 py-2">
                              {{ item.invoice_no }}
                            </span>
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            {{ item.partner.name }}
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            <span class="status" :class="item.status_color">{{
                              item.status_str
                            }}</span>
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            <span class="status delivery">{{
                              item.delivery_status_str
                            }}</span>
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            {{ item.grand_total_str }}
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            {{ item.invoice_due_date_str }}
                          </td>
                        </tr>
                        <tr v-if="item.selected" class="column-detail bg-white rounded shadow-xl left-0 bottom-0">
                          <td></td>
                          <td colspan="7">
                            <table class="items-center w-full bg-transparent border-collapse">
                              <thead>
                                <tr>
                                  <th
                                    class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs border-none whitespace-nowrap font-semibold text-left">
                                    Product
                                  </th>
                                  <th
                                    class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs border-none whitespace-nowrap font-semibold text-left">
                                    Description
                                  </th>
                                  <th
                                    class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs border-none whitespace-nowrap font-semibold text-left">
                                    Quantity
                                  </th>
                                  <th
                                    class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs border-none whitespace-nowrap font-semibold text-left">
                                    Price
                                  </th>
                                  <th
                                    class="relative px-6 bg-white text-black-400 align-bottom border border-solid border-blueGray-100 py-3 text-xs border-none whitespace-nowrap font-semibold text-left">

                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <Loading color='#FF8F15' v-model:active="isLoadingDetail" :is-full-page="true" />
                                <tr v-for="(item, index) in invoice.invoice_items" :key="index">
                                  <td
                                    class="px-6 py-3 text-sm text-black-400 align-middle whitespace-nowrap font-semibold text-left">
                                    {{ item.product_name }}</td>
                                  <td
                                    class="px-6 py-3 text-sm text-black-400 align-middle whitespace-nowrap font-semibold text-left">
                                    {{ item.description }}</td>
                                  <td
                                    class="px-6 py-3 text-sm text-black-400 align-middle whitespace-nowrap font-semibold text-left">
                                    {{ item.qty }}</td>
                                  <td
                                    class="px-6 py-3 text-sm text-black-400 align-middle whitespace-nowrap font-semibold text-left">
                                    {{ item.final_amount_str }}</td>
                                  <td v-if="index == 0"
                                    class="px-6 py-3 text-sm text-black-400 align-middle whitespace-nowrap font-semibold text-left"
                                    :rowspan="invoice.invoice_items.length">
                                    <div class="flex items-center	">
                                      <button @click="resendInvoice(invoice.invoice.id, invoice.invoice.invoice_no)"
                                        class="btn-action rounded-full mr-2">
                                        <i class="far fa-paper-plane text-xs"></i>
                                      </button>
                                      <a class="btn-action rounded-full mr-2" :href="invoice.invoice.doc_url" target="_blank">
                                        <DownloadIcon class="w-5 h-5" />
                                        <span>Download PDF</span>
                                      </a>
                                      <a @click="removeData(invoice.invoice.id)" class="btn-action rounded-full pointer">
                                        <i class="far fa-trash-alt"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </template> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>
<script>
// import NavbarComponent from "../components/Navbar.vue";
import SidebarComponent from "../components/Sidebar.vue";
import Header from "../components/header/Header.vue";
import LineChartComponent from "../components/LineChart.vue";
import PieChart from "../components/PieChart.vue";
import Tab from '../components/tabs/Tab.vue';
import Tabs from '../components/tabs/Tabs.vue';
export default {
  name: "summary-page",
  components: {
    SidebarComponent,
    Header,
    LineChartComponent,
    PieChart,
    Tabs,
    Tab
  },
  data() {
    return {
    };
  },
};
</script>
