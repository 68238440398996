import ApiService from "./api.service";
// import moment from "moment";
// import JwtService from "./jwt.service";

//actions
export const GET_SETTLEACCOUNT = "getSettlementAccount";
export const CREATE_SETTLEACCOUNT = "createSettlementAccount";

//mutations 
export const SET_SETTLEACCOUNT = "setSettleAccount";

const convertStatus = {
  pending: {
    status: "On review",
    color: "pending"
  },
  active: {
    status: "Active",
    color: "paid"
  }
}

const state = {
  settlement_accounts: [],
}

const getters = {
  currentSettleAccount(state) {
    return state.settlement_accounts;
  },
}

const actions = {
  [GET_SETTLEACCOUNT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/settlement-accounts", params)
        .then(async ({ data }) => {
          await context.commit(SET_SETTLEACCOUNT, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [CREATE_SETTLEACCOUNT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/settlement-accounts", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations={
  [SET_SETTLEACCOUNT](state, data) {
    data.rows = data.rows.map(d => {
      d.status_str = d.status ? convertStatus[d.status].status : "";
      d.status_color = d.status ? convertStatus[d.status].color : "";
      return d
    })
    // handle total page, and etc
    let limit = 10;
    const start = 1 + ((data.page - 1) * limit)
    const maxData = data.page * limit;
    let last_row = 0;
    if (data.count > maxData) {
      last_row = maxData;
    } else {
      last_row = (data.count % limit) + ((data.page - 1) * limit);
    }

    // handle pagination
    const total_page = Math.ceil(data.count / limit);
    let page_list = [];
    const diff = total_page - data.page;
    if (diff >= 5) {
      page_list = [data.page, data.page+1, "...", total_page-1, total_page];
    } else {
      if (total_page > 5) {
        for (let i=total_page-4; i<=total_page; i++) {
          page_list.push(i);
        }
      } else {
        for (let i=1; i<=total_page; i++) {
          page_list.push(i);
        }
      }
    }
    
    Object.assign(data, { start, last_row, page_list, total_page })
    state.settlement_accounts = data;
    return;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};
