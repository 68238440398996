import ApiService from "./api.service";
import moment from "moment";
import JwtService from "./jwt.service";

//actions
export const GET_INVOICES = "getInvoices";
export const GET_INVOICE = "getInvoice";
export const CREATE_INVOICE = "createInvoice";
export const GET_TEMPLATES = "getTemplates";
export const DELETE_INVOICE = "deleteInvoice";
export const RESEND_INVOICE = "resendInvoice";
export const GET_SETTING = "getInvoiceSetting";
export const UPDATE_SETTING = "updateInvoiceSetting";
export const UPDATE_INVOICE = "updateInvoice"
export const REJECT_INVOICE = "rejectInvoice"
export const GET_REJECT_INVOICE = "getRejectInvoice"

//mutations 
export const SET_INVOICES = "setInvoices";
export const SET_INVOICE = "setInvoice";
export const SET_CREATE_INVOICE = "setCreateInvoice";
export const SET_TEMPLATES = "setTemplates";
export const SET_SETTING = "setInvoiceSetting"
export const REMOVE_INVOICE_DETAIL = "removeInvoiceDetail";

const convertStatus = {
  pending: {
    status: "Pending",
    color: "draft"
  },
  waiting_payment: {
    status: "Waiting Payment",
    color: "pending",
  },
  paid: {
    status: "Paid",
    color: "paid"
  },
  canceled: {
    status: "Canceled",
    color: "canceled"
  },
  draft: {
    status: "Draft",
    color: "draft"
  },
  overdue: {
    status: "Overdue",
    color: "canceled"
  },
  rejected: {
    status: "Rejected",
    color: "canceled"
  }
}

const convertDeliveryStatus = {
  pending: "Pending",
  sent: "Sent",
  draft: "Draft",
}

const state = {
  invoices: {},
  invoice: {},
  createInvoice: JwtService.getCreateInvoice(),
  templates: [],
  invoiceSetting: {}
}

const getters = {
  currentInvoices(state) {
    return state.invoices;
  },
  currentInvoice(state) {
    return state.invoice;
  },
  currentCreateInvoice(state) {
    return state.createInvoice;
  },
  currentTemplates(state) {
    return state.templates;
  },
  currentInvoiceSetting(state) {
    return state.invoiceSetting
  }
}

const actions = {
  [GET_INVOICES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/invoices", params)
        .then(async ({ data }) => {
          Object.assign(data.data, { page: params.page })
          await context.commit(SET_INVOICES, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/invoices/"+params)
        .then(async ({ data }) => {
          await context.commit(SET_INVOICE, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [CREATE_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      var options = {}
      if (params.isBuffer) {
        options.responseType = 'arraybuffer'
        options.responseEncoding = 'binary'
      }
      ApiService.post("api/invoices", params.data, params.query, options)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPDATE_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      var options = {}
      if (params.isBuffer) {
        options.responseType = 'arraybuffer'
        options.responseEncoding = 'binary'
      }
      ApiService.patch("api/invoices/" + params.id, params.data, params.query, options)
        .then(async ({ data }) => {
          await context.commit(REMOVE_INVOICE_DETAIL, params.id);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  [GET_TEMPLATES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/invoice-templates", params)
        .then(async ({ data }) => {
          await context.commit(SET_TEMPLATES, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [DELETE_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("api/invoices/"+params)
      .then(async ({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
    })
  },
  [RESEND_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/invoices/" + params + "/resend")
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  [GET_SETTING](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/company/setting/invoice")
        .then(async ({ data }) => {
          await context.commit(SET_SETTING, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPDATE_SETTING](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("api/company/setting/invoice", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [REJECT_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/invoices/${params.token}/reject`, params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_REJECT_INVOICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/invoices/${params.token}/reject`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations={
  [SET_INVOICES](state, data) {

    // handle convert data
    // let i = 0;
    data.rows = data.rows.map(d => {
      if(!['paid', 'rejected'].includes(d.status) && moment(d.invoice_due_date).isBefore(moment(), 'day')) {
        d.status = "overdue"
      }
      d.status_str = d.status ? convertStatus[d.status].status : "";
      d.status_color = d.status ? convertStatus[d.status].color : "";
      d.delivery_status_str = d.delivery_status ? convertDeliveryStatus[d.delivery_status] : "";
      let price = d.grand_total;
      let paid = d.paid_amount || 0;
      if (d.grand_total && d.grand_total > 0) price = Number(d.grand_total).toLocaleString("id-ID");
      if (d.paid_amount && d.paid_amount > 0) paid = Number(d.paid_amount).toLocaleString("id-ID");
      d.grand_total_str = `Rp. ${price}`;
      d.paid_amount_str = `Rp. ${paid}`;
      d.created_at_str = moment(d.created_at).format("DD/MM/YYYY");
      d.invoice_date_str = moment(d.invoice_date).format("DD/MM/YYYY");
      d.invoice_due_date_str = moment(d.invoice_due_date).format("DD/MM/YYYY");
      return d
    })

    // handle total page, and etc
    let limit = 10;
    const start = 1 + ((data.page - 1) * limit)
    const maxData = data.page * limit;
    let last_row = 0;
    if (data.count >= maxData) {
      last_row = maxData;
    } else {
      last_row = (data.count % limit) + ((data.page - 1) * limit);
    }

    // handle pagination
    const total_page = Math.ceil(data.count / limit);
    let page_list = [];
    const diff = total_page - data.page;
    if (diff >= 5) {
      page_list = [data.page, data.page+1, "...", total_page-1, total_page];
    } else {
      if (total_page > 5) {
        for (let i=total_page-4; i<=total_page; i++) {
          page_list.push(i);
        }
      } else {
        for (let i=1; i<=total_page; i++) {
          page_list.push(i);
        }
      }
    }
    
    Object.assign(data, { start, last_row, page_list, total_page })
    state.invoices = data;
    return;
  },
  [SET_INVOICE](state, data) {
    if (!state.invoice[data.invoice.id]) {
      const getData = state.invoice;
      let i = 0;
      for (const d of data.invoice_items) {
        let price = d.final_amount
        if (d.final_amount && d.final_amount > 0) price = Number(d.final_amount).toLocaleString("id-ID");
        data.invoice_items[i].final_amount_str = `Rp. ${price}`;
        i += 1;
      }
      Object.assign(getData, { [data.invoice.id]: data })
      state.invoice = getData;
    }
    return;
  },
  [SET_CREATE_INVOICE](state, data) {
    state.createInvoice = data;
    JwtService.saveCreateInvoice(data);
    return;
  },
  [SET_TEMPLATES](state, data) {
    state.templates = data;
    return;
  },
  [SET_SETTING](state, data) {
    state.invoiceSetting = data
    return
  },
  [REMOVE_INVOICE_DETAIL](state, data) {
    delete state.invoice[data.invoice_id];
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
