// import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
// import AccountRegister from '../components/register/AccountRegister.vue';
// import CompanyRegister from '../components/register/CompanyRegister.vue';
import Loan from '../views/Loan.vue'
import RequestLoan from '../views/RequestLoan.vue'
import Report from '../views/Report.vue'
import Summary from '../views/Summary.vue'

import { store } from "@/store/index";
const routerHistory = createWebHistory(process.env.BASE_URL)

const routes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		component: () => import(/* webpackChunkName: "Dashboard" */ `../views/Dashboard`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'dashboard',
			title: 'Dashboard',
		}
	},
	{
		path:"/invoice",
		name: "Invoice",
		component: () => import(/* webpackChunkName: "Invoice" */ `../views/Invoice`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'invoice',
			title: 'Invoice',
		}
	},
	{
		path:"/invoice/:id",
		name: "EditInvoice",
		component: () => import(/* webpackChunkName: "EditInvoice" */ `../components/EditInvoice.vue`),
		meta: { haveAuthenticated: true }
	},
	{
		path: "/login",
		name: "Login",
		component: () => import(/* webpackChunkName: "Login" */ `../views/Login`),
		meta: { checkAuthenticated: true }
	},
	{
		path: "/register",
		name: "Register",
		component: () => import(/* webpackChunkName: "Register" */ `../views/Register`),
		meta: { checkAuthenticated: true }
	},
	{
		path: "/template-invoice",
		name: "Template Invoice",
		component: () => import(/* webpackChunkName: "invoiceTemplate" */ `../components/register/invoiceTemplate.vue`),
		meta: { haveAuthenticated: true }
	},
	{
		path:"/forget-password",
		name: "Forget Password",
		component: () => import(/* webpackChunkName: "ForgotPassword" */ `../views/ForgetPasswordPage`),
		meta: { checkAuthenticated: true }
	},
	{
		path: "/",
		redirect: "/login"
	},
	{
		path: "/loan",
		component: Loan,
		meta: { haveAuthenticated: true }
	},
	{
		path:"/request-loan",
		component:RequestLoan,
		meta: { haveAuthenticated: true }
	},
	{
		path: "/create-invoice",
		name: "Create Invoice",
		component: () => import(/* webpackChunkName: "CreateInvoice" */ `../components/CreateInvoice.vue`),
		meta: { haveAuthenticated: true }
	},
	{
		path:"/mitra",
		name: "Mitra",
		component: () => import(/* webpackChunkName: "Mitra" */ `../views/Mitra.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'mitra',
			title: 'Mitra',
		}
	},
	{
		path:"/sales-person",
		name: "Sales Person",
		component: () => import(/* webpackChunkName: "Sales" */ `../views/SalesPerson.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'sales-person',
			title: 'Sales Person',
		}
	},
	{
		path:"/payments",
		name: "Payments",
		component: () => import(/* webpackChunkName: "Payments" */ `../views/Payments.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'payments',
			title: 'Payments',
		}
	},
	{
		path: "/payments/create",
		name: "CreateManualPayment",
		component: () => import(/* webpackChunkName: "CreateManualPayment" */ `../views/CreateManualPayment.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'payments',
			title: 'Create Payment',
		}
	},
	{
		path:"/profile",
		name: "Profile",
		component: () => import(/* webpackChunkName: "Profile" */ `../views/Profile.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'profile',
			title: 'Profile',
		}
	},
	{
		path:"/settings",
		name: "Settings",
		component: () => import(/* webpackChunkName: "Settings" */ `../views/Settings.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'settings',
			title: 'Settings',
		}
	},
	{
		path:"/settings/users",
		name: "Users",
		component: () => import(/* webpackChunkName: "Users" */ `../views/Users.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'users',
			title: 'Roles & Users',
		}
	},
	{
		path: "/paylink",
		name: "Paylink",
		component: () => import(/* webpackChunkName: "Paylink" */ `../views/Paylink.vue`),
	},
	{
		path: "/reject",
		name: "RejectInvoice",
		component: () => import(/* webpackChunkName: "RejectInvoice" */ `../views/RejectInvoice.vue`),
		meta: { haveAuthenticated: false },
	},
	{
		path:"/settings/invoice",
		name: "InvoiceSettings",
		component: () => import(/* webpackChunkName: "InvoiceSettings" */ `../views/InvoiceSettings.vue`),
		meta: {
			haveAuthenticated: true,
			layout: 'dashboard',
			sidebar_name: 'invoice-settings',
			title: 'Invoice Settings',
		}
	},
	{
		path: "/report",
		name: "Report",
		component: Report
	},
	{
		path: "/summary",
		name: "Summary",
		component: Summary
	},
	{
		path: "/reset-password/:token",
		name: "ResetPassword",
		component: () => import(/* webpackChunkName: "ResetPassword" */ `../views/ResetPassword`)
	},
	{
		path: "/verify/:token",
		name: "VerifyRegister",
		component: () => import(/* webpackChunkName: "VerifyRegister" */ `../views/VerifyRegister`)

	}
]

const router = createRouter({
  history: routerHistory,
//   base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.haveAuthenticated) && !store.getters.isAuthenticated) {
    console.log("goto login")
    next()
	} else if (to.matched.some(record => record.meta.checkAuthenticated) && store.getters.isAuthenticated) {
    next({ name: "Dashboard" });
	} else {
    next();
  }
});

export default router;