<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <div class="relative bg-white-600 flex justify-between items-center py-10 px-8">
        <a href="#" class="back shadow-md"><ChevronLeftIcon /></a>
        <h1 class="text-4xl font-semibold text-black-800">Request Loan</h1>
        <div class="menu-header">
          <div class="relative inline-block text-left">
            <div>
              <button @click="open = !open" type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                ID
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-if="open" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">EN</a>
              </div>
            </div>
          </div>
          <a href="#" class="text-sm font-medium text-black-700 mr-2"><span class="has-notify relative inline-block align-middle"><img :src="BellIcon" alt="icon" /></span> Notification</a>
          <a href="#" class="text-sm font-medium text-black-700 mr-2"><span class="relative inline-block align-middle"><img :src="CogIcon" alt="icon" /></span> Settings</a>
          <a href="#" class="text-sm font-medium text-black-700 mr-2">
            <span class="avatar active">
            <span class="avatar-wrapp">
              <img :src="Avatar" />
            </span> 
            </span> 
            Oliver Reed</a>
        </div>
      </div>
      <!-- Header -->
      <div class="relative bg-white-600 pb-10 h-screen">
        <div class="px-4 md:px-10 mx-auto w-4">
          <div class="bg-white rounded-lg px-5 py-10 mt-10">
            <div class="py-5 form-request mx-auto">
              <div class="mb-5">
                <label class="block text-sm font-semibold">
                  Client Name
                </label>
                <select name="dropdown" class="w-full text-xs bg-gray-100 border-0 shadow-0 py-3 rounded-lg">
                  <option value="Web Design" selected>Select Client</option>
                </select>
              </div>
              <div class="mb-5">
                <label class="block text-sm font-semibold">
                  Invoice
                </label>
                <input type="text" class="w-full py-3 bg-gray-100 rounded-lg px-3 text-xs mr-1 border-none" placeholder="Invoice Number" />
              </div>
              <div class="mb-5">
                <label class="block text-sm font-semibold">
                  Loan Ammount
                </label>
                <div class="relative">
                  <span class="bg-gray-100 rounded-lg px-3 py-3 block absolute top-0 left-0 text-xs">Rp</span>
                  <input type="text" class="w-full py-3 bg-gray-100 rounded-lg pl-6 pr-3 text-xs mr-1 border-none" />
                </div>
              </div>
              <div class="mb-5">
                <label class="block text-sm font-semibold">
                  Payments
                </label>
                <input type="text" class="w-full py-3 bg-gray-100 rounded-lg px-3 text-xs mr-1 border-none" placeholder="3x" />
              </div>

              <div class="flex justify-end items-center mt-12">
                <button @click.prevent="toggleModal" type="button" class="inline-flex justify-center rounded-lg border border-transparent  px-6 py-2 bg-white text-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-3 w-auto text-xs">
                  Cancel
                </button>
                <button type="button" class="inline-flex justify-center rounded-lg border border-transparent  px-6 py-2 bg-orange-500 text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ml-3 w-auto text-xs">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import NavbarComponent from "../components/Navbar.vue";
import SidebarComponent from "../components/Sidebar.vue";
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import Avatar from "../assets/img/avatar.png";
import BellIcon from "../assets/img/bell-icon.svg";
import CogIcon from "../assets/img/cog-icon.svg";

export default {
  name: "request-loan-page",
  components: {
    // NavbarComponent,
    SidebarComponent,
    ChevronLeftIcon,
  },
  data() {
    return {
      date: new Date().getFullYear(),
      open: false,
      Avatar: Avatar,
      CogIcon: CogIcon,
      BellIcon: BellIcon
    };
  },
};
</script>

