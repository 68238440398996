<template>
  <div>
    <sidebar-component active="report"></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100 h-screen pb-wrap">
      <!-- Header -->
      <Header page="Laporan" />
      <div class="px-4 md:px-8 mx-auto w-full">
        <div class="flex flex-wrap mt-4">
          <div class="w-full px-4">
            <h2 class="font-semibold text-2xl mb-4 text-gray-900">Invoice Report</h2>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="totalInvoice" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Laporan Invoice</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="paidInvoice" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Laporan Stok</h3>
                  <p class="text-gray-400">See report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="unpaidInvoice" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Laporan Umur Piutang</h3>
                  <p class="text-gray-400">See report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full px-4">
            <h2 class="font-semibold text-2xl mb-4 text-gray-900">Akunting</h2>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="InvoiceIcon" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Balance</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="TrialBalance" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Trial Balance</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="NeracaIcon" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Neraca Keuangan</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="totalInvoice" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Neraca Saldo</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="IncomeIcon" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Laporan Laba Rugi</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer w-full xl:w-4/12 mb-4 px-4">
            <div class="box-settings w-full py-10 px-12 bg-white rounded-lg shadow-smooth h-full flex ">
              <div class="flex align-center">
                <div class="mr-5">
                  <img :src="LedgerIcon" alt="icon" />
                </div>
                <div>
                  <h3 class="font-semibold text-xl mb-1 text-gray-900">Laporan Buku Besar</h3>
                  <p class="text-gray-400">See all report on your sales, receivables and client statement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import NavbarComponent from "../components/Navbar.vue";
import SidebarComponent from "../components/Sidebar.vue";
import Header from "../components/header/Header.vue";
import TrialBalance from "@/assets/img/trial-balance-icon.svg";
import NeracaIcon from "@/assets/img/neraca-icon.svg";
import IncomeIcon from "@/assets/img/income-icon.svg";
import LedgerIcon from "@/assets/img/ledger-icon.svg";
import InvoiceIcon from "@/assets/img/invoice-icon1.svg";
import paidInvoice from "../assets/img/paid-invoice.svg";
import unpaidInvoice from "../assets/img/unpaid-invoice.svg";
import totalInvoice from "../assets/img/total-icon.svg";
import sentInvoice from "../assets/img/sent-icon.svg";
export default {
  name: "report-page",
  components: {
    SidebarComponent,
    Header
  },
  data() {
    return {
      TrialBalance,
      NeracaIcon,
      IncomeIcon,
      InvoiceIcon,
      paidInvoice,
      unpaidInvoice,
      sentInvoice,
      totalInvoice,
      LedgerIcon
    };
  },
};
</script>
