<template>
  <div>
    <div class="notification-payment">
      <ul>
        <li class="rounded-lg bg-white mb-3 px-4 py-4" v-for="(item, index) in notifList" :key="index">
          <div class="pay-info">
            <span class="status mb-2" :class="{ [item.status_color]: true }">
              {{ item.status_str }}
            </span>
            <h3 class="font-bold text-sm mb-2">Invoice: {{ item.invoice_no }}</h3>
            <div class="flex justify-between items-center text-sm">
              <span>Total Tagihan</span>
              <span class="text-orange-500 font-bold">Rp.185.000</span>
            </div>
          </div>
          <div class="btn-wrap align-middle">
            <button @click="$emit('closeNotif', index)">Close</button>
            <button @click="$emit('viewDetail', item)">View Detail</button>
          </div>
        </li>
        <!-- <li class="rounded-lg bg-white mb-3 px-4 py-4">
          <div class="pay-info">
            <span class="status accepted mb-2">Pembayaran Diterima</span>
            <h3 class="font-bold text-sm mb-2">INV-20210921-00001-RjS4K</h3>
            <div class="flex justify-between items-center text-sm">
              <span>Total Tagihan</span>
              <span class="text-orange-500 font-bold">Rp.185.000</span>
            </div>
          </div>
          <div class="btn-wrap">
            <button>Close</button>
            <button>View Detail</button>
          </div>
        </li> -->
        <!-- <li class="rounded-lg bg-white mb-3 px-4 py-4 old">
          <div class="pay-info">
            <span class="status rejected mb-2">Pembayaran Ditolak</span>
            <h3 class="font-bold mb-2">INV-20210921-00001-RjS4K</h3>
            <div class="flex justify-between items-center">
              <span>Total Tagihan</span>
              <span class="text-orange-500 font-bold">Rp.185.000</span>
            </div>
          </div>
          <div class="btn-wrap">
            <button>Close</button>
            <button>View Detail</button>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    notifList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
    };
  },
};
</script>
