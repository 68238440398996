const ID_TOKEN_KEY = "hG6QC3a9Gy";
const CREATE_INVOICE_KEY = "3lP4lYbelw";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getCreateInvoice = () => {
  const data = window.localStorage.getItem(CREATE_INVOICE_KEY);
  return JSON.parse(data);
};

export const saveCreateInvoice = token => {
  const data = JSON.stringify(token);
  window.localStorage.setItem(CREATE_INVOICE_KEY, data);
};

export const destroyCreateInvoice = () => {
  window.localStorage.removeItem(CREATE_INVOICE_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getCreateInvoice,
  saveCreateInvoice,
  destroyCreateInvoice
}
