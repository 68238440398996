<template>
  <div>
    <sidebar-component active="loan"></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <div class="relative bg-white-600 flex justify-between items-center py-10 px-8">
        <a href="#" class="back shadow-md"><ChevronLeftIcon /></a>
        <h1 class="text-4xl font-semibold text-black-800">Loan</h1>
        <div class="menu-header">
          <div class="relative inline-block text-left">
            <div>
              <button @click="open = !open" type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                ID
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-if="open" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">EN</a>
              </div>
            </div>
          </div>
          <a href="#" class="text-sm font-medium text-black-700 mr-2"><span class="has-notify relative inline-block align-middle"><img :src="BellIcon" alt="icon" /></span> Notification</a>
          <a href="#" class="text-sm font-medium text-black-700 mr-2"><span class="relative inline-block align-middle"><img :src="CogIcon" alt="icon" /></span> Settings</a>
          <a href="#" class="text-sm font-medium text-black-700 mr-2">
            <span class="avatar active">
            <span class="avatar-wrapp">
              <img :src="Avatar" />
            </span> 
            </span> 
            Oliver Reed</a>
        </div>
      </div>
      <!-- Header -->
      <div class="relative bg-white-600 pb-10 h-screen">
        <div class="px-4 md:px-10 mx-auto w-4">
          <div>
            <!-- Card stats -->
            <div>
              <!-- Tab -->
              <Tabs>
                <Tab title="Loan">
                  <div class="mt-4 flex justify-between items-center">
                    <div class="w-33 rounded-lg bg-white px-4 py-4 mr-4 flex items-center">
                      <div class="icon-wrapp">
                        <img :src="TotalLoanIcon" alt="icon" />
                      </div>
                      <div class="ml-3">
                        <label class="text-sm text-gray-200 block mb-1">Total Loan</label>
                        <p class="text-lg text-black-700 font-semibold">$782.01</p>
                      </div>
                    </div>
                    <div class="w-33 rounded-lg bg-white px-4 py-4 mr-4 flex items-center">
                      <div class="icon-wrapp">
                        <img :src="RequestLoanIcon" alt="icon" />
                      </div>
                      <div class="ml-3">
                        <label class="text-sm text-gray-200 block mb-1">Request Loan</label>
                        <p class="text-lg text-black-700 font-semibold">$782.01</p>
                      </div>
                    </div>
                    <div class="w-33 rounded-lg bg-white px-4 py-4 flex items-center">
                      <div class="icon-wrapp">
                        <img :src="SuccessLoanIcon" alt="icon" />
                      </div>
                      <div class="ml-3">
                        <label class="text-sm text-gray-200 block mb-1">Success Loan</label>
                        <p class="text-lg text-black-700 font-semibold">$782.01</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between align-center mt-4 mb-4">
                    <div>
                      <div class="inline-block relative">
                        <i class="fas fa-search absolute block mt-3 ml-3 text-gray-200"></i>
                        <input class="py-2 rounded pl-10 mr-1" placeholder="Search" />
                      </div>
                      <select name="dropdown" class="border-0 shadow-0 py-2 rounded">
                        <option value="Web Design" selected>Filters</option>
                      </select>
                    </div>
                    <div class="flex">
                      <LoanCalcModal />
                      <ModalLoan />
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table
                      class="items-center w-full bg-transparent border-collapse"
                      >
                      <thead>
                        <tr>
                          <th
                            style="width: 20px;"
                            class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            <input type="checkbox" class="appearance-none border border-blueGray-200 rounded checked:bg-orange-600 checked:border-transparent ...">
                          </th>
                          <th
                            class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Create Date
                            <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                              <ChevronUpIcon class="text-black-400 text-xs -mb-half"/>
                              <ChevronDownIcon class="text-black-400 text-xs" />
                            </span>
                          </th>
                          <th
                            class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Loan No
                            <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                              <ChevronUpIcon class="text-black-400 text-xs -mb-half"/>
                              <ChevronDownIcon class="text-black-400 text-xs" />
                            </span>
                          </th>
                          <th
                            class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Client Name
                            <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                              <ChevronUpIcon class="text-black-400 text-xs -mb-half"/>
                              <ChevronDownIcon class="text-black-400 text-xs" />
                            </span>
                          </th>
                          <th
                            class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Payment Status
                            <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                              <ChevronUpIcon class="text-black-400 text-xs -mb-half"/>
                              <ChevronDownIcon class="text-black-400 text-xs" />
                            </span>
                          </th>
                          <th
                            class="relative px-6 bg-white text-black-400 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Amount
                            <span class="absolute top-0 right-0 block w-5 h-5 mt-1 lg:mr-2">
                              <ChevronUpIcon class="text-black-400 text-xs -mb-half"/>
                              <ChevronDownIcon class="text-black-400 text-xs" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="relative bg-white border border-solid border-blueGray-100 border-l-0 border-r-0 ">
                          <td class="px-6 py-3 text-xs text-black-400 align-top whitespace-nowrap font-semibold text-left">
                            <input type="checkbox" class="ml-3 appearance-none border border-blueGray-200 rounded checked:bg-orange-600 checked:border-transparent ...">
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-middle whitespace-nowrap font-semibold text-left">
                            
                              5/30/14
                          </td>
                          <td class="px-6 py-3 text-xs text-black-400 align-middle whitespace-nowrap font-semibold text-left">LO/2021/001</td>
                          <td class="px-6 py-3 text-xs text-black-400 align-middle whitespace-nowrap font-semibold text-left">Bessie Cooper</td>
                          <td class="px-6 py-3 text-xs text-black-400 align-middle whitespace-nowrap font-semibold text-left"><span class="status canceled">Canceled</span></td>
                          <td class="px-6 py-3 text-xs text-black-400 align-middle whitespace-nowrap font-semibold text-left">Rp.100.000.000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab>
                <Tab title="History">Hello From Tab 2</Tab>
              </Tabs>
              <!-- End Tab -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import NavbarComponent from "../components/Navbar.vue";
import SidebarComponent from "../components/Sidebar.vue";
import Tab from '../components/tabs/Tab.vue';
import Tabs from '../components/tabs/Tabs.vue';
import ModalLoan from '../components/LoanModal.vue';
import LoanCalcModal from '../components/LoanCalcModal.vue';
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon } from '@heroicons/vue/solid';
import Avatar from "../assets/img/avatar.png";
import SeeIcon from "../assets/img/see-icon.svg";
import EditIcon from "../assets/img/edit-icon.svg";
import RemoveIcon from "../assets/img/remove-icon.svg";
import BellIcon from "../assets/img/bell-icon.svg";
import CogIcon from "../assets/img/cog-icon.svg";
import TotalLoanIcon from "../assets/img/total-loan-icon.svg";
import RequestLoanIcon from "../assets/img/request-loan-icon.svg";
import SuccessLoanIcon from "../assets/img/success-loan-icon.svg";

export default {
  name: "loan-page",
  components: {
    // NavbarComponent,
    SidebarComponent,
    Tabs,
    Tab,
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronLeftIcon,
    ModalLoan,
    LoanCalcModal
  },
  data() {
    return {
      date: new Date().getFullYear(),
      open: false,
      Avatar: Avatar,
      SeeIcon: SeeIcon,
      EditIcon: EditIcon,
      RemoveIcon: RemoveIcon,
      CogIcon: CogIcon,
      BellIcon: BellIcon,
      TotalLoanIcon: TotalLoanIcon,
      RequestLoanIcon: RequestLoanIcon,
      SuccessLoanIcon: SuccessLoanIcon
    };
  },
};
</script>

