import ApiService from "./api.service";

export const GET_BUSINESS_ENTITIES = "getBusinessEntities";
export const GET_BUSINESS_TYPES = "getBusinessTypes";
export const GET_TAXES = "getTaxes";
export const GET_BANK_LIST = "getBankList";
export const UPLOAD_MEDIA = "uploadMedia";

export const SET_BUSINESS_ENTITIES = "setBusinessEntities";
export const SET_BUSINESS_TYPES = "setBusinessTypes";
export const SET_TAXES = "setTaxes";
export const SET_BANK_LIST = "setBankList";

const employeeCounts = [
  { id: "level1", value: "1-15 Karyawan" },
  { id: "level2", value: "16-50 Karyawan" },
  { id: "level3", value: "51-100 Karyawan" },
  { id: "level4", value: "101-200 Karyawan" },
  { id: "level5", value: "201-500 Karyawan" },
]

const state = {
  businessEntities : [],
  businessTypes: [],
  employeeCounts,
  taxes: [],
  bank_list: [],
}

const getters = {
  currentBusinessEntities(state) {
    return state.businessEntities
  },
  currentBusinessTypes(state) {
    return state.businessTypes
  },
  currentEmployeeCounts(state) {
    return state.employeeCounts
  },
  currentTaxes(state) {
    return state.taxes
  },
  currentBankList(state) {
    return state.bank_list
  }
}

const actions = {
  [GET_BUSINESS_ENTITIES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/business-entity", { per_page: 100 })
        .then(async ({ data }) => {
          await context.commit(SET_BUSINESS_ENTITIES, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_BUSINESS_TYPES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/business-type")
        .then(async ({ data }) => {
          await context.commit(SET_BUSINESS_TYPES, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_TAXES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/tax")
        .then(async ({ data }) => {
          await context.commit(SET_TAXES, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_BANK_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/bank")
        .then(async ({ data }) => {
          await context.commit(SET_BANK_LIST, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPLOAD_MEDIA](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.upload("api/media", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
}

const mutations = {
  [SET_BUSINESS_ENTITIES](state, data) {
    state.businessEntities = data;
    return;
  },
  [SET_BUSINESS_TYPES](state, data) {
    state.businessTypes = data;
    return;
  },
  [SET_TAXES](state, data) {
    state.taxes = data;
    return;
  },
  [SET_BANK_LIST](state, data) {
    state.bank_list = data;
    return;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};
