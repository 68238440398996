import ApiService from "./api.service";
// import moment from "moment";
// import JwtService from "./jwt.service";

//actions
export const GET_DASHBOARD_ANALYTIC = "getDashboardAnalytic";

//mutations 
export const SET_DASHBOARD_ANALYTIC = "setDashboardAnalytic";

// const convertStatus = {
//   init: {
//     status: "Waiting Payment",
//     color: "pending"
//   },
//   processed: {
//     status: "Processed",
//     color: "paid"
//   },
//   created: {
//     status: "Waiting Payment",
//     color: "pending"
//   },
//   canceled: {
//     status: "Canceled",
//     color: "canceled"
//   },
//   expired: {
//     status: "Expired",
//     color: "canceled"
//   },
// }

// const convertDeliveryStatus = {
//   pending: "Pending",
//   sent: "Sent",
//   draft: "Draft",
// }

const state = {
  analytic_invoice: {},
  analytic_invoice_amount: {},
  analytic_payment: {},
}

const getters = {
  invoiceAnalytic(state) {
    return state.analytic_invoice;
  },
  invoiceAmountAnalytic(state) {
    return state.analytic_invoice_amount
  },
  paymentAmountAnalytic(state) {
    return state.analytic_payment
  },
}

const actions = {
  [GET_DASHBOARD_ANALYTIC](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/analytic/dashboard", params)
        .then(async ({ data }) => {
          await context.commit(SET_DASHBOARD_ANALYTIC, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations={
  [SET_DASHBOARD_ANALYTIC](state, data) {
		state.analytic_invoice = data.invoice
		state.analytic_invoice_amount = data.invoice_amount
    state.analytic_payment = data.payment
    return;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};
