import axios from "axios";
import JwtService from "./jwt.service";
import AuthStore from "./authStore";
import config from "@/ConfigProvider";

const ApiService = {
  init() {
    axios.defaults.baseURL = config.value("API_URL");
    axios.defaults.timeout = 30000;
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  throwLogin(error) {
    if(error?.response?.status == 401) {
      AuthStore.state.isAuthenticated = false;
      JwtService.destroyToken();
      window.location = '/login'
    }
    return error
  },

  async get(resource, params) {
    this.setHeader();
    return axios.get(`${resource}`, { params }).catch(error => {
      this.throwLogin(error)
      throw error;
    });
  },

  post(resource, params, query, config = {}) {
    this.setHeader();
    // return axios.post(`${resource}`, params, { params: query }, config);
    return axios({ method: "post", url: `${resource}`, data: params, params: query, ...config });
  },

  upload(resource, params) {
    this.setHeader();
    let formData = new FormData();
    console.log(params)
    formData.append("media", params);
    return axios.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  update(resource, slug, params) {
    this.setHeader();
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    this.setHeader();
    return axios.put(`${resource}`, params);
  },

  patch(resource, params, query, config = {}) {
    this.setHeader();
    return axios({ method: "patch", url: `${resource}`, data: params, params: query, ...config });
  },

  delete(resource) {
    this.setHeader();
    return axios.delete(resource);
  }
};

export default ApiService;
