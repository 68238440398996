<template>
  <div class="relative bg-white-600 flex justify-between items-center py-10 px-8">
    <a href="#" class="back shadow-md" style="display: none !important">
      <ChevronLeftIcon /></a>
    <h1 class="text-3xl lg:text-4xl font-semibold text-black-800">{{ page }}</h1>
    <div class="menu-header">
      <div class="relative inline-block text-left">
        <!-- <div>
          <button @click="open = !open" type="button"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            id="menu-button" aria-expanded="true" aria-haspopup="true">
            ID
            <svg class="-mr-1 ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
          </button>
        </div> -->

        <!--
          Dropdown menu, show/hide based on menu state.

          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        -->
        <div v-if="open"
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
              id="menu-item-0">EN</a>
          </div>
        </div>
      </div>
      <div class="relative inline-block text-left" id="divNotif">
        <button id="btnNotif" @click="handlerNotifBtn" type="button"
          class="text-hidden md:text-sm font-medium text-black-700 mr-3 relative z-index-2">
          <span id="spanNotif" class="has-notify relative inline-block align-middle"><img id="iconNotif" :src="BellIcon" alt="icon" /></span>
          Notification
        </button>
        <div v-if="notyDropdown"
          class="dropdown-custom normal origin-top-right sm:origin-top-left absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-1/2"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="title">
            <h3>Notification</h3>
          </div>
          <div class="py-1 align-center empty" role="none" v-if="!notifList?.rows?.length">
            <img :src="EmptyNotify" alt="icon" class="mx-auto" />
            <p>
              Belum Ada Notifikasi dari Supplier &amp; Buyer Anda
            </p>
            <span>
              Yuk Kirimkan Dokumen Anda sekarang!
            </span>
          </div>
          <div class="noty-list overflow-auto" v-if="notifList?.rows?.length > 0">
            <ul>
              <li v-for="(item, i) in notifList.rows" :key="i">
                <div class="date-notif">
                  {{ item.created_at_str }}
                </div>
                <div class="flex justify-between items-center">
                  <div class="no-order">
                    <a :href="item.payload?.invoice?.doc_url" target="_blank">{{ item.invoice_no }}</a>
                  </div>
                  <span class="status" :class="{ [item.status_color]: true }">
                    {{ item.status_str }}
                  </span>
                </div>
                <div class="flex justify-between items-center total">
                  <span>
                    Total
                  </span>
                  <span class="price">
                    {{ item.total_amount_str }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <a href="javascript:;" @click="$router.push({ name: 'Settings' })" class="px-5 text-hidden md:text-sm font-medium text-black-700 mr-3"><span class="relative inline-block align-middle"><img
            :src="CogIcon" alt="icon" /></span> Settings</a>
      <div class="relative inline-block text-left" id="divUser">
        <button id="btnUser" @click="userDropdown = !userDropdown" type="button"
          class="btn-dropdown text-hidden md:text-sm font-medium text-black-700">
          <span class="avatar active mr-3">
            <span class="avatar-wrapp" id="spanUser">
              <img :src="Avatar" id="iconUser" />
            </span>
          </span>
          {{ currentMyProfile?.profile?.fullname }} ({{ currentMyProfile?.profile?.role?.name }})
        </button>
        <div v-if="userDropdown"
          class="dropdown-custom origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1" role="none">
            <span class="email-user block text-gray-500 break-words">{{ currentMyProfile?.profile?.email }}</span>
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a href="javascript:;" @click="$router.push({ name: 'Profile' })"
              class="block py-2 text-sm text-center menu-profile text-orange-500" role="menuitem" tabindex="-1"
              id="menu-item-0">Atur Profil Pengguna
              <ArrowRightIcon class="icon" /></a>
            <a @click="logout()" class="pointer text-gray-700 block py-2 text-xs logout" role="menuitem" tabindex="-1"
              id="menu-item-0">
              <img width="15" :src="LogOutIcon" alt="icon" />
              <span class="text-sm">Keluar dari VENTENY</span>
            </a>
          </div>
        </div>
      </div>
      <notification-payment
        @viewDetail="viewDetailNotif"
        @closeNotif="(index) => popUpNotifList.splice(index, 1)"
        :notif-list="popUpNotifList" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { ChevronLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import BellIcon from "@/assets/img/bell-icon.svg";
import Avatar from "@/assets/img/avatar.png";
import CogIcon from "@/assets/img/cog-icon.svg";
import LogOutIcon from "@/assets/img/logout-icon.svg";
import EmptyNotify from "@/assets/img/empty-notify.svg";
import { GET_MY_PROFILE, PURGE_AUTH, GET_NOTIFICATIONS } from "@/store/authStore";
import config from "@/ConfigProvider";
import openSocket from 'socket.io-client';
import NotificationPayment from '../NotificationPayment.vue';
const socket = openSocket(config.value("API_URL"), {
  transports: ['websocket'],
  upgrade: false,
  reconnection: true,
  reconnectionAttempts: 1000,
  reconnectionDelay: 1000,
});
// import moment from 'moment'

export default {
  components: {
    ChevronLeftIcon,
    ArrowRightIcon,
    NotificationPayment,
  },
  props: {
    page: {
      type: String,
      default: "Dashboard"
    }
  },
  data() {
    return {
      BellIcon,
      Avatar,
      CogIcon,
      LogOutIcon,
      EmptyNotify,
      open: false,
      userDropdown: false,
      notyDropdown: false,
      reconnect: true,
      isConnected: false,
      popUpNotifList: []
    }
  },
  mounted() {
    this.socketHandler()
    this.$store.dispatch(GET_MY_PROFILE).then(() => {
      this.doConnectSocket()
    });
    this.$store.dispatch(GET_NOTIFICATIONS)
    document.addEventListener('click', (e) => {
      if (!['btnNotif', 'iconNotif', 'divNotif', 'spanNotif'].includes(e.target.id)) {
        this.notyDropdown = false
      }
      if(!['btnUser', 'iconUser', 'divUser', 'spanUser'].includes(e.target.id)) {
        this.userDropdown = false
      }
      if(e.target.id !== 'menu-button') {
        this.open = false
      }
    })
  },
  computed: {
    currentMyProfile() {
      return this.$store.getters.currentMyProfile;
    },
    notifList() {
      return this.$store.getters.currentNotifications;
    }
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
      this.$router.push({
        name: "Login"
      });
      this.$nextTick(() => {
        this.reconnect = false;
        socket.disconnect()
      })
    },
    doConnectSocket() {
      if (this.currentMyProfile?.profile && !this.isConnected) {
        const company_id = this.currentMyProfile?.profile.company_id
        socket.disconnect();
        if (!this.reconnect) {
          return
        }
        socket.connect();
        socket.emit('web_notif', company_id);
      }
    },
    socketHandler() {
      socket.on('connect', () => {
        console.log("connected")
        this.isConnected = true
      })
      socket.on('disconnect', () => {
        this.isConnected = false
        this.$nextTick(() => this.doConnectSocket())
      })
      socket.on('listen_web_notif', (data) => {
        const convertStatus = this.$store.getters.convertStatusPaymentNotif;
        data.created_at_str = moment().format("DD MMM YYYY, HH:mm")
        data.status_str = data.payload.invoice.status ? convertStatus[data.payload.invoice.status].status : "-"
        data.status_color = data.payload.invoice.status ? convertStatus[data.payload.invoice.status].color : ""
        data.invoice_no = data.payload.invoice.invoice_no
        data.total_amount_str = "Rp." + data.payload.invoice.grand_total.toLocaleString()
        this.popUpNotifList.push(data)

        setTimeout(() => { // Hide after several second
          this.popUpNotifList.shift()
        }, 1000 * 10)
        this.$store.dispatch(GET_NOTIFICATIONS);
      })
    },
    viewDetailNotif(item){
      if(item.type == "payment") this.$router.push("/payments")
    },
    handlerNotifBtn() {
      this.notyDropdown = !this.notyDropdown
    }
  },
  watch: {
    currentMyProfile() {
      this.$nextTick(() => this.doConnectSocket())
    }
  }
}
</script>