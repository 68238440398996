import App from "@/App.vue";
import { createApp } from "vue";
import router from './router';
import { store } from "@/store/index";
import ApiService from "@/store/api.service";

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/sass/index.scss"
import './index.css'

ApiService.init();
const app = createApp(App)
app.config.devtools = true
app.use(router)
app.use(VueLoading, {
    color: '#FF8F15',
});
app.use(store)

app.mount('#app')
