// import Vue from "vue";
import { createStore } from 'vuex'

import authStore from "./authStore";
import invoice from "./invoice.module";
import payment from "./payment.module";
import partners from "./partners.module";
import salespersons from "./salespersons.module";
import general from "./general.module";
import company from "./company.module";
import settlement_account from "./settlement_account.module";
import analytic from "./analytic.module";
import users from "./users.module";

export const store = createStore({
  modules: {
    authStore,
    invoice,
    partners,
    salespersons,
    general,
    company,
    payment,
    settlement_account,
    analytic,
    users
  }
})
// Vue.use(Vuex);

// export default new Vuex.Store({
//   modules: {
//       authStore,
//   }
// });
