import ApiService from "./api.service";

export const GET_ALL_SALES_PERSONS = "getAllSalesPersons";
export const GET_SALES_PERSONS = "getSalesPersons";
export const POST_SALES_PERSON = "postSalesPerson";
export const DELETE_SALES_PERSON = "deleteSalesPerson";
export const UPDATE_SALES_PERSON = "updateSalesPerson";

export const SET_ALL_SALES_PERSONS = "setAllSalesPersons";
export const SET_SALES_PERSONS = "setSalesPersons";
export const SET_SALES_PERSON = "setSalesPerson";

const state = {
  allSalesPersons: [],
  salesPersons: {},
  salesPerson: {}
}

const getters = {
  currentAllSalesPersons(state) {
    return state.allSalesPersons;
  },
  currentSalesPersons(state) {
    return state.salesPersons;
  },
  currentSalesPerson(state) {
    return state.salesPerson;
  }
}

const actions = {
  [GET_ALL_SALES_PERSONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/salesperson", { per_page: 100 })
        .then(async ({ data }) => {
          await context.commit(SET_ALL_SALES_PERSONS, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_SALES_PERSONS](context, params) {
    return new Promise((resolve, reject) => {
      Object.assign(params, { per_page: 10 })
      ApiService.get("api/salesperson", params)
        .then(async ({ data }) => {
          Object.assign(data.data, { page: params.page })
          await context.commit(SET_SALES_PERSONS, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [POST_SALES_PERSON](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/salesperson", params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [DELETE_SALES_PERSON](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("api/salesperson/"+params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPDATE_SALES_PERSON](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.patch("api/salesperson/"+params.id, params.data )
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations = {
  [SET_ALL_SALES_PERSONS](state, data) {
    state.allSalesPersons = data;
    return;
  },
  [SET_SALES_PERSONS](state, data) {
    
    // handle total page, and etc
    let limit = 10;
    const start = 1 + ((data.page - 1) * limit)
    const maxData = data.page * limit;
    let last_row = 0;
    if (data.count > maxData) {
      last_row = maxData;
    } else {
      last_row = (data.count % limit) + ((data.page - 1) * limit);
    }

    // handle pagination
    const total_page = Math.ceil(data.count / limit);
    let page_list = [];
    const diff = total_page - data.page;
    if (diff >= 5) {
      page_list = [data.page, data.page+1, "...", total_page-1, total_page];
    } else {
      if (total_page > 5) {
        for (let i=total_page-4; i<=total_page; i++) {
          page_list.push(i);
        }
      } else {
        for (let i=1; i<=total_page; i++) {
          page_list.push(i);
        }
      }
    }
    
    Object.assign(data, { start, last_row, page_list, total_page })

    state.salesPersons = data;
    return;
  },
  [SET_SALES_PERSON](state, data) {
    state.salesPerson = data;
    return;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};
