<template>
  <div id="app">
    <dashboard v-if="$route.meta.layout == 'dashboard'">
      <router-view/>
    </dashboard>
    <router-view v-else />
  </div>
</template>
<script>
import Dashboard from './layout/Dashboard.vue';
export default {
  name: 'App',
  components: {
    Dashboard,
  },
};
</script>