import moment from "moment";
import ApiService from "./api.service";
import JwtService from "./jwt.service";

//actions
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const VERIFY_REGISTER_OTP = "verifyRegisterOTP";
export const RESEND_OTP = "resendOTP";
export const GET_MY_PROFILE = "getMyProfile";
export const UPDATE_MY_PROFILE = "updateMyProfile";
export const UPDATE_MY_PASSWORD = "updateMyPassword";
export const GET_NOTIFICATIONS = "getNotifications";
export const VERIFY_TOKEN_RESET = "verifyTokenResetPassword"
export const VERIFY_TOKEN_REGISTER = "verifyTokenRegister"
export const RESET_PASSWORD = "resetPassword"

//mutations 
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const INIT_RESET_PASSWORD = "initResetPassword";
export const SET_MY_PROFILE = "setMyProfile";
export const SET_NOTIFICATIONS = "setNotifications"

const convertStatus = {
  pending: {
    status: "Pending",
    color: "draft"
  },
  waiting_payment: {
    status: "Waiting Payment",
    color: "pending",
  },
  paid: {
    status: "Paid",
    color: "paid"
  },
  canceled: {
    status: "Canceled",
    color: "canceled"
  },
  draft: {
    status: "Draft",
    color: "draft"
  },
  overdue: {
    status: "Overdue",
    color: "canceled"
  },
  rejected: {
    status: "Rejected",
    color: "canceled"
  }
}

const state={
  isAuthenticated: !!JwtService.getToken(),
  myProfile: {},
  notifications: [],
  convertStatus: convertStatus
}

const getters={
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentMyProfile(state) {
    return state.myProfile;
  },
  currentNotifications(state) {
    return state.notifications;
  },
  convertStatusPaymentNotif(state) {
    return state.convertStatus
  }
}
const actions={
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/login", credentials)
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/register", credentials)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [INIT_RESET_PASSWORD](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/auth/reset-password/", params.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [VERIFY_REGISTER_OTP](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/auth/verify/"+params.token, { code: params.code })
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [VERIFY_TOKEN_REGISTER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/auth/verify/" + params.token)
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [RESEND_OTP](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/otp", params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [GET_MY_PROFILE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/profile")
        .then(({ data }) => {
          context.commit(SET_MY_PROFILE, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [UPDATE_MY_PROFILE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch("api/profile", param)
        .then(({ data }) => {
          context.commit(SET_MY_PROFILE, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [UPDATE_MY_PASSWORD](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch("api/profile/change-password", param)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err)
      });
    })
  },
  [GET_NOTIFICATIONS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/notifications", param)
      .then(({ data }) => {
        context.commit(SET_NOTIFICATIONS, data.data);
        resolve(data);
      })
      .catch((err) => {
        reject(err)
      });
    })
  },
  [VERIFY_TOKEN_RESET](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/auth/reset-password/" + param)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [RESET_PASSWORD](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch("api/auth/reset-password/" + param.token, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  }
}

const mutations={
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    JwtService.saveToken(data.token);
    return;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    JwtService.destroyToken();
  },
  [SET_MY_PROFILE](state, data) {
    state.myProfile = data;
    return;
  },
  [SET_NOTIFICATIONS](state, data) {
    data.rows = data.rows.map(v => {
      v.created_at_str = moment(v.created_at).format("DD MMM YYYY, HH:mm")
      v.status_str = v.payload.invoice.status ? convertStatus[v.payload.invoice.status].status : "-"
      v.status_color = v.payload.invoice.status ? convertStatus[v.payload.invoice.status].color : ""
      v.invoice_no = v.payload.invoice.invoice_no
      v.total_amount_str = "Rp." + v.payload.invoice.grand_total.toLocaleString()
      return v;
    })
    state.notifications = data
    return;
  }
}

export default {
    state,
    getters,
    actions,
    mutations
  };
  
