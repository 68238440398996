<template>
  <div class="tabs">
    <ul class='tabs__header'>
      <li 
        v-for="title in tabTitles"
        :key="title"
        :class="{selected: title == selectedTitle}"
        @click="selectedTitle = title"
        >
        {{ title }} 
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { ref, provide } from 'vue'
export default {
  setup (props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab && tab.props && tab.props.title))
    const selectedTitle = ref(tabTitles.value[0])

    provide('selectedTitle', selectedTitle)
    return {
      selectedTitle,
      tabTitles,
    }
  },
}
</script>