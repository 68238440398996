<template>
  <div>
    <modal :title="mconfig.title" :description="mconfig.text" :isVisibility="mconfig.show" :type="mconfig.type"
        @closeModal="mconfig.show = false" />
    <sidebar-component :active="$route.meta.sidebar_name"></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100 h-full pb-wrap">
      <Header :page="$route.meta.title" />
      <slot/>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "../components/Sidebar.vue";
import Header from "../components/header/Header.vue";
import Modal from '../components/modal/Modal.vue';

export default {
  name: 'DashboardLayout',
  mounted() {
  },
  methods: {
  },
  data() {
    return {
      mconfig: {
        show: false,
        title: "",
        text: "",
        type: "success"
      }
    }
  },
  components: {
    SidebarComponent,
    Header,
    Modal,
  },
};
</script>