import ApiService from "./api.service";

export const UPDATE_COMPANY = "updateCompany";
export const GET_COMPANY_PROFILE = "getCompanyProfile";

export const SET_COMPANY_PROFILE = "setCompanyProfile";

const state = {
  companyProfile: {}
}

const getters = {
  currentCompanyProfile(state) {
    return state.companyProfile;
  }
}

const actions = {
  [UPDATE_COMPANY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.patch("api/company/profile", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_COMPANY_PROFILE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/company")
        .then(async ({ data }) => {
          context.commit(SET_COMPANY_PROFILE, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations = {
  [SET_COMPANY_PROFILE](state, data) {
    state.companyProfile = data;
    return;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}