<template>
  <div>
    <div v-if="isVisibility" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="h-full flex items-center justify-center min-h-screen pt-0 px-0 pb-0 text-center sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 overlay transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. --> 
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="modal-md inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-5 py-5">
            <div class="block modal-body">
              <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-bold text-gray-900 mb-2" id="modal-title">
                  Pilih Jenis Mitra
                </h3>
                <p class="text-sm mmb-2">Silahkan lengkapi data calon mitra baru anda</p>
                <div class="mt-2 w-full modal-content">
                  <div class="mb-3">
                    <div class="flex justify-between items-center w-full">
                      <div class="mitra-type mr-2 bg-gray-100 rounded-lg text-center">
                        <img :src="Supplier" class="mx-auto" />
                        <p class="text-xs mt-3">Supplier</p>
                      </div>
                      <div class="selected mitra-type mr-2 bg-gray-100 rounded-lg text-center">
                        <img :src="Client" class="mx-auto" />
                        <p class="text-xs mt-3">Client</p>
                      </div>
                      <div class="mitra-type bg-gray-100 rounded-lg text-center">
                        <img :src="ClinetSpupplier" class="mx-auto" />
                        <p class="text-xs mt-3">Client &amp; Supplier</p>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Nomor Mitra<span class="text-red-500 text-xs">*</span>
                    </label>
                    <select name="dropdown" class="w-full text-xs bg-gray-100 border-0 shadow-0 py-3 rounded">
                      <option value="Web Design" selected>Pilih tipe invoice</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Nama Mitra<span class="text-red-500 text-xs">*</span>
                    </label>
                    <select name="dropdown" class="w-full text-xs bg-gray-100 border-0 shadow-0 py-3 rounded">
                      <option value="Web Design" selected>Pilih Client</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Sales Person<span class="text-red-500 text-xs">*</span>
                    </label>
                    <select name="dropdown" class="w-full text-xs bg-gray-100 border-0 shadow-0 py-3 rounded">
                      <option value="Web Design" selected>Pilih Salae Person</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Project<span class="text-red-500 text-xs">*</span>
                    </label>
                    <select name="dropdown" class="w-full text-xs bg-gray-100 border-0 shadow-0 py-3 rounded">
                      <option value="Web Design" selected>Pilih Project</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Nomor Mitra<span class="text-red-500 text-xs">*</span>
                    </label>
                    <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Nomor Mitra" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Nama Mitra<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Udin Sedunia" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Nama Kontak<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Jenis Perusahaan<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Email<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Virtual Accounts<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      No Telepon<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Website<span class="text-red-500 text-xs">*</span>
                    </label>
                     <input type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan" />
                  </div>
                  <div class="mb-3">
                    <label class="block text-sm font-semibold">
                      Alamat<span class="text-red-500 text-xs">*</span>
                    </label>
                     <textarea type="text" class="w-full py-3 bg-gray-100 rounded px-3 text-xs mr-1 border-none" placeholder="Mandiri Kalasan"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white shadow-top px-4 py-3 px-6 flex flex-row-reverse">
            
            <button type="button" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 bg-orange-500 text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ml-3 w-auto text-xs">
              Buat Invoice
            </button>
            <button @click.prevent="toggleModal" type="button" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 bg-gray text-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-3 w-auto text-xs">
              Batalkan
            </button>
          </div>
        </div>
      </div>
    </div>  
    <button
      type="button"
      class="text-sm bg-orange-500 text-white px-8 py-3 rounded-lg outline-none focus:outline-none mr-0"
      style="transition: all 0.15s ease 0s;"
      @click.prevent="toggleModal"
    >
      <PlusIcon class="h-5 w-5 inline-block" />
      Create New
    </button>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import { PlusIcon } from '@heroicons/vue/solid';
import ClinetSpupplier from "../assets/img/client-supplier.svg";
import Supplier from "../assets/img/supplier.svg";
import Client from "../assets/img/client.svg";
export default {
  name: "mitra-modal",
  components: {
    PlusIcon
  },
  data() {
    return {
      ClinetSpupplier: ClinetSpupplier,
      Supplier: Supplier,
      Client: Client,
      isVisibility: false
    }
  },
  methods: {
    toggleModal() {
      this.isVisibility =  !this.isVisibility
    }
  }
};
</script>
