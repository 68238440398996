<template>
  <div class="z-40">
    <div v-if="isVisibility" class="fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="h-full flex items-center justify-center min-h-screen pt-0 px-0 pb-0 text-center sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 overlay transition-opacity z-40" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. --> 
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="modal-sm inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full z-40">
          <div class="bg-white px-5 py-5">
            <div class="block modal-body">
              <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-center items-center">
                <div class="mt-2 w-full px-5 py-5 text-center items-center">
                  <XCircleIcon
                    v-if="['delete', 'error'].includes(type)"
                    style="mx: auto; display: inline; margin-bottom: 20px;"
                    class="w-16 h-16 stroke-current text-red-500"
                  />
                  <CheckCircleIcon
                    v-if="type == 'success'"
                    style="mx: auto; display: inline; margin-bottom: 20px;"
                    class="w-16 h-16 stroke-current text-success"
                  />
                  <InformationCircleIcon
                    v-if="type == 'info'"
                    style="mx: auto; display: inline; margin-bottom: 20px;"
                    class="w-16 h-16 stroke-current text-orange-500"
                  />
                  <ExclamationIcon
                    v-if="['confirm', 'warning'].includes(type)"
                    style="mx: auto; display: inline; margin-bottom: 20px;"
                    class="w-16 h-16 stroke-current text-yellow-500"
                  />
                  <h3 class="text-xl font-bold">
                    <span v-if="!title">Yakin ingin menghapus {{ name }} ini?</span>
                    <span v-if="title">{{ title }}</span>
                  </h3>
                  <p>
                    <span v-if="!description"> Data {{ name }} terpilih akan dihapus secara permanen dan tidak dapat di kembalikan</span>
                    <span style="white-space: pre-line" v-if="description">{{ description }}</span>
                  </p>
                  <div class="mt-4" v-if="['confirm', 'delete'].includes(type)">
                    <button @click.prevent="$emit('closeModal')" type="button" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 bg-gray text-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-auto text-xs">
                      Batalkan
                    </button>
                    <button type="button" @click="$emit('onSubmit')" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 focus:outline-none text-white focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ml-3 w-auto text-xs" :class="{
                      'bg-red-600': type == 'delete',
                      'hover:bg-red-700': type == 'delete',
                      'bg-orange-500': type == 'confirm',
                      'hover:bg-orange-600': type == 'confirm'
                      }">
                      Ya
                    </button>
                  </div>
                  <div class="mt-4" v-if="type == 'success'">
                    <button type="button" @click="$emit('closeModal')" style="background-color: #29823B;" class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 w-auto text-xs">
                      OK, Terima kasih!
                    </button>
                  </div>
                  <div class="mt-4" v-if="['error', 'info', 'warning'].includes(type)">
                    <button type="button" @click="$emit('closeModal')"
                      class="inline-flex justify-center rounded-lg border border-transparent shadow-sm px-6 py-2 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 w-auto text-xs"
                      :class="{
                        'bg-red-600': type == 'error',
                        'hover:bg-red-700': type == 'error',
                        'bg-orange-500': ['info', 'warning'].includes(type),
                        'hover:bg-orange-600': ['info', 'warning'].includes(type),
                      }">
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
    <!-- <button
      type="button"
      class="text-sm bg-orange-500 text-white px-8 py-3 rounded-lg outline-none focus:outline-none mr-0"
      style="transition: all 0.15s ease 0s;"
      @click.prevent="toggleModal"
    >
      Confirmation
    </button> -->
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon } from '@heroicons/vue/solid';
import ClinetSpupplier from "@/assets/img/client-supplier.svg";
import Supplier from "@/assets/img/supplier.svg";
import Client from "@/assets/img/client.svg";
import deleteIcon from "@/assets/img/delete-icon.svg";
import successIcon from "@/assets/img/success-icon.svg";

export default {
  name: "confirmation-modal",
  props: {
    isVisibility: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "success"
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: ""
    },
  },
  components: {
    CheckCircleIcon,
    XCircleIcon,
    InformationCircleIcon,
    ExclamationIcon
  },
  data() {
    return {
      ClinetSpupplier: ClinetSpupplier,
      Supplier: Supplier,
      Client: Client,
      deleteIcon,
      successIcon
      // isVisibility: false
    }
  },
  methods: {
    // toggleModal() {
    //   this.isVisibility =  !this.isVisibility
    // }
  }
};
</script>
