import ApiService from "./api.service";

export const GET_ALL_USERS = "getAllUsers";
export const GET_USERS = "getUsers";
export const POST_USER = "postUser";
export const DELETE_USER = "deleteUser";
export const UPDATE_USER = "updateUser";
export const GET_ROLES = "getRoles";

export const SET_ALL_USERS = "setAllUsers";
export const SET_USERS = "setUsers";
export const SET_ROLES = "setRoles";

const state = {
  allUsers: [],
  users: {},
  roles: [],
}

const getters = {
  currentAllUsers(state) {
    return state.allUsers;
  },
  currentUsers(state) {
    return state.users;
  },
  currentRoles(state) {
    return state.roles
  }
}

const actions = {
  [GET_ALL_USERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/users", { per_page: 100 })
        .then(async ({ data }) => {
          await context.commit(SET_ALL_USERS, data.data.rows);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_USERS](context, params) {
    return new Promise((resolve, reject) => {
      Object.assign(params, { per_page: 10 })
      ApiService.get("api/users", params)
        .then(async ({ data }) => {
          Object.assign(data.data, { page: params.page })
          await context.commit(SET_USERS, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [POST_USER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/users", params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [DELETE_USER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("api/users/"+params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPDATE_USER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.patch("api/users/"+params.id, params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_ROLES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/roles", params)
        .then(async ({ data }) => {
          await context.commit(SET_ROLES, data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations = {
  [SET_ALL_USERS](state, data) {
    state.allUsers = data;
    return;
  },
  [SET_USERS](state, data) {
    
    // handle total page, and etc
    let limit = 10;
    const start = 1 + ((data.page - 1) * limit)
    const maxData = data.page * limit;
    let last_row = 0;
    if (data.count > maxData) {
      last_row = maxData;
    } else {
      last_row = (data.count % limit) + ((data.page - 1) * limit);
    }

    // handle pagination
    const total_page = Math.ceil(data.count / limit);
    let page_list = [];
    const diff = total_page - data.page;
    if (diff >= 5) {
      page_list = [data.page, data.page+1, "...", total_page-1, total_page];
    } else {
      if (total_page > 5) {
        for (let i=total_page-4; i<=total_page; i++) {
          page_list.push(i);
        }
      } else {
        for (let i=1; i<=total_page; i++) {
          page_list.push(i);
        }
      }
    }
    
    Object.assign(data, { start, last_row, page_list, total_page })

    state.users = data;
    return;
  },
  [SET_ROLES](state, data) {
    state.roles = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
